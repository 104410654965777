/* RewardsPage.css */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap');

.rewards-page {
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%; /* Default to full width */
  
  animation: fadeIn 2s;
}

.top-section {
  background-color: #e75600;
  background: linear-gradient(to bottom, #e1771f, #e1771f, #e75600, #e75600, #e75600, #cb521c);

  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.top-section::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: 13px;
  height: 15px; /* Adjust height of the border */
  background: linear-gradient(to right, #a6824b, #b88f4c, #af8a50, lightyellow, #af8a50, #b88f4c, #a6824b); /* Shiny gold gradient */
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; /* Space between elements */
  padding: 20px; /* Padding to ensure content is visible */
}

.images-container {
  display: flex;
  justify-content: center;
  gap: 20px; /* Space between images */
}

.rewardheader {
  font-size: 18px;
  background: linear-gradient(to right, #af8a50, lightyellow, lightyellow, lightyellow, #af8a50); /* Shiny gold gradient */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: white;
  margin-top: -5px;
  background-clip: text;
  text-decoration: none; /* Remove default text decoration */
}
.toptext{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
}
.text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
  border: 2px solid; /* Border width */
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #af8a50, lightyellow, #af8a50); /* Shiny gold gradient */
  padding: 10px; /* Padding inside the border */
  border-radius: 50px;
  margin-left: 3px;
  margin-right: 3px; 
}
.description {
  font-size: 18px;
  background: linear-gradient(to right, #af8a50, #af8a50); /* Shiny gold gradient */
 /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;*/
  color: #af8a50;
  font-weight: bold;
  background-clip: text;
}
.textstyle{
  font-size: 14px;
}
.leveltext{
  font-size: 15px;
  font-weight: bold;
  background-clip: text;
}
.memberlevel{
  font-size: 20px;
  color: #e75600;
  font-weight: bold;
  background-clip: text;
  margin-top: -5px;
  font-family: 'Cinzel', serif;
}
.points{
  font-size: 20px;
  color: #e75600;
  font-weight: bold;
  background-clip: text;
  font-family: 'Cinzel', serif;
}
.logo {
  height: 50px;
  width: auto;
}

.progress-container {
  width: 80%;
  margin: 20px auto;

}

.progress-bar {
  height: 30px;
  width: 100%;
  /*background-color: #e0e0e0;*/
  background-color: black;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  overflow: hidden;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
  
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.progress-pointer {
    
    font-size: 16px;
    color: #e75600;
    font-weight: bold;
    position: relative;
    text-align: left;
}
.progress-bar-fill {
  height: 100%;
  width: 0;
  background-color: #e75600;
  background: linear-gradient(to right, #f7a84b, #e75600);
  text-align: center;
  line-height: 30px;
  color: white;
  font-weight: bold;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  transition: width 0.5s ease-in-out;
}
.progress-text1 {
  margin-top: 10px;
  font-size: 14px;
  text-align:left
}
.progress-text2 {
  margin-top: 10px;
  font-size: 14px;
  text-align:right
}
@media (min-height: 500px) {
  .top-section {
    height: 40%;
  }
}

@media (min-width: 768px) {
  .rewards-page {
    width: 50%;
  }
  .top-section {
    height: 50%;
  }
}
