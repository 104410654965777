.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.headerimage{
  height: 45px;
  width: 45px;
  margin-left: 5px;
  margin-top: 3px;
}
.jbslider{
margin-top: 20%;
}
.header{
  border-bottom-style:inset;
  height: 50px;
}
.headertext{
  margin-top: -32px;
  margin-left: 55px;
  font-weight: bold;
  text-decoration: none;
  font-family: "Lexend Deca";
  color: #e75600;
}
.maindiv{
  background-color: #f2f2f2;
  opacity:0.8;
    overflow-y: scroll;
    position:fixed;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    z-index:1000;
}
.content {
  width: 50%;
  margin-left: 25%;
  vertical-align: middle;
  animation: fadeIn 2s;
}
.businessname {
  vertical-align:middle;
  text-align: center;
  animation: fadeIn 2s;
  font-weight: bold;
  font-size: 20px;
  font-family: "Lexend Deca";
  color: #e75600;

}
.itemname{
  vertical-align:middle;
  animation: fadeIn 2s;
  font-weight: bold;
  font-size: 14px;
  font-family: "Lexend Deca";
  text-align: center;
  color: #e75600;
}
.itemprice{
  vertical-align:middle;
  animation: fadeIn 2s;
  font-weight: bold;
  font-size: 12px;
  font-family: "Lexend Deca";
  text-align: center;
  margin-top: '-20px';
}
.itemavailable{
 
  animation: fadeIn 2s;
  font-weight: bold;
  font-size: 12px;
  font-family: "Lexend Deca";
  margin-top: 2px;
  margin-left: 2px;
  margin-right: 25%;
    

}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin-top: 10%;
}

.ratingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  text-align: center
}

.ratingTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 19px;
  color: #e75600;
  font-weight: bold;
  
}

.submitButton {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.itemdesc{
  vertical-align:middle;
  animation: fadeIn 2s;
  font-weight: bold;
  font-size: 10px;
  font-family: "Lexend Deca";
  text-align: center;
  flex-wrap: contain;
  margin-left: 5px;
  margin-right: 5px;
  word-wrap: break-word;
}
.itemdescription{
  vertical-align:middle;
  animation: fadeIn 2s;
  font-weight: bold;
  font-size: 10px;
  font-family: "Lexend Deca";
  margin-left: 7px;
  margin-right: 7px;
  margin-bottom: 17px;
  word-wrap: break-word;
  text-overflow: ellipsis;
  white-space: wrap;
  text-align: justify;
}
.itemcards{

  height:100%;
  flex-wrap: contain;
}

.filterstyle{
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 50%;
  height:100%;
  object-fit: contain;
  margin-left: 25%; 
}
.filters{
  margin-left: 5px; 
  margin-top: 2px;
  margin-bottom: 2px;
  border-radius: 5px;
  width: 30%;
}
.filterby{
  margin-left: 6px; 
  margin-top: 2px;
  margin-bottom: 2px;
  border-radius: 5px;
}
.searchbar{
  margin-left: 5px; 
  margin-top: 2px;
  margin-bottom: 2px;
  border-radius: 5px;
}

.cardstyle{
  border-radius: 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 60%;
  height:90%;
  object-fit: contain;
  background-color: #FFF4D4;
  
  
}
.cardimage{
  margin-left: 25%;
  margin-right: 25%;
  width: 85px;
  height: 95px;
  object-fit: contain;
  margin-top: 2%;
}
.modal-content {
  width: 80vw;
  max-width: 800px;
  height: auto;
}

.modal-image {
  width: 100%;
  height: auto;
}
.modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
 .loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #e75600; /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 10px;
    animation: spin 1.5s linear infinite;
    margin-left: 25%;
  }
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;
  margin-left: 25%;
  vertical-align: middle;
  
  align-items: center;
  align-self: center;
  justify-content: center;
}
.lds-ripple div {
  position: absolute;
  border: 8px solid #e75600;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.6, 2, 1) infinite;
}
.load{
  vertical-align: middle;
  align-items: center;
  align-self: center;
  justify-content: center;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-30px);
  }
  60% {
    transform: translateX(30px);
  }
}

.bouncing-ball {
  width: 20px;
  height: 20px;
  background-color: #e75600;
  border-radius: 50%;
  animation: bounce 2s infinite;
}
.bouncing-ball:nth-child(2) {
  animation-delay: 0.33s;
}

.bouncing-ball:nth-child(3) {
  animation-delay: 0.66s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
@media screen and (max-width: 560px) {
  .ReactModal__Content {
    width: 80vw; /* Adjust width for smaller screens */
    max-width: 75%;
    max-height:70% /* No maximum width for smaller screens */
  }
  .modal-content img {
    max-width: 50%; /* Adjust as needed */
  }
  .searchbar{
    margin-left: 5%; 
    margin-top: 2px;
    margin-bottom: 2px;
    border-radius: 5px;
    width: 85%;
  }
  .filters{
    margin-left: 5%; 
    margin-top: 2px;
    margin-bottom: 2px;
  
    border-radius: 5px;
  }
  .lds-ripple{
    margin-left: 32%;
    margin-top: 50%;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin-top: 20%;
  }
  .filterby{
    margin-left: 5%; 
    margin-top: 2px;
    margin-bottom: 2px;
    border-radius: 5px;
  }
  .cardimage{
    margin-left: 25%;
    margin-right: 25%;
    width: 65px;
    height:75px;
    margin-top: 2%;
    object-fit: contain;
  }
  .cardstyle{
    border-radius: 12px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 80%;
    height:90%;
    object-fit: contain;
  }.itemname{
    vertical-align:middle;
    animation: fadeIn 2s;
    font-weight: bold;
    font-size: 14px;
    font-family: "Lexend Deca";
    text-align: center;
    color: #e75600;
    height: 30px;
  }
  .jbslider{
    margin-top: 80%;
    }
  .itemavailable{
 
    animation: fadeIn 2s;
    font-weight: bold;
    font-size: 12px;
    font-family: "Lexend Deca";
    margin-top: 2px;
    margin-left: 2px;
    margin-right: 25%;
  }
  .itemdescription{
    vertical-align:middle;
    animation: fadeIn 2s;
    font-weight: bold;
    font-size: 10px;
    font-family: "Lexend Deca";
    margin-left: 7px;
    margin-right: 7px;
    margin-bottom: 17px;
    word-wrap: break-word;
    white-space: wrap;
    text-align:justify;
  }
  .filterstyle{
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 50%;
    height:100%;
    object-fit: contain;
    margin-left: 25%; 
  }
  .content {
    width: 100%;
    margin-left: 0; 
  }
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
